/* Provide sufficient contrast against white background */
 
* {
  font-family: "Open Sans", sans-serif;
}

a {
  color: inherit;
}

  a:hover {
      color: inherit;
  }

/* Backgrounds */
.bg-whisper {
  background-color: #eee;
}

.bg-skyblue {
  background-color: #89b2cd;
}
.bg-skyblue-80-tint {
  background-color: #e7f0f5;
}

.bg-darkblue {
  background-color: #00415e;
}

.bg-black {
  background-color: black;
} 
.bg-prussian-blue {
  background: #00415e !important;
}
.bg-cerulean {
    background-color: #00649b;
}

.bg-pale-sky {
    background-color: #6c757d;
}

.bg-bright-turquoise {
    background-color: #27D1F1;
}

.bg-danger {
    background-color: red;
}
 
/* Typography */  

p {
  font-size: 16px;
}
 
label{
  font-size: 16px; 
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

/* Text */
.text-gray{
  color: gray;
}
.text-red{
  color:red; 
}

.text-cerulean {
  color: #00649b;
}

.text-skyblue {
  color: #89b2cd;
}

.text-prussian-blue {
  color: #00415e;
}


/*Buttons*/
.btn-light {
  background-color: #eee;
  color: #00415e;
}

.btn-skyblue {
  background-color: #89b2cd;
  color: #fff;
}

.btn-prussian-blue {
  background-color: #00415e!important;
  color: #ffffff!important;
}

.btn-matterhorn {
  background-color: #535353!important;
  color: #ffffff!important;
   
}
 
.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}
 









.k-header {
  background-color: #00415E!important;
  color: #ffffff!important;

}

input, select, .form-control, .form-select, .btn {
    border-radius: 0px !important;
}