 
.k-header {
    background-color: #F8F9FA!important;
    color: #343A40 !important;
    
}

.k-combobox  {
    border-radius: 0px !important;
}

li.k-item.k-last, li.k-item.k-first {
    color: #00415E ;
    font-weight: 600;


}

li.k-item.k-last:hover, li.k-item.k-first:hover {
        color: #00649b;
}

li.k-item.k-last.k-active, li.k-item.k-first.k-active {
    color: #343A40;
    background-color: #F8F9FA;
    border-radius: 0%;
    font-weight: 600;


}
span.k-column-title{
    font-weight: 600;
}

div.k-grid-column-menu.k-grid-filter{
    background-color: #F8F9FA;
    color: #343A40;

}
div.k-grid-column-menu.k-grid-filter:hover{
    color:#00415E;

}
a.k-link{
    color: #2978A8!important;
}

a.k-link.k-selected{
    color: #ffffff!important;
    background-color:  #2978A8!important;
}

div.k-content.k-active{
    padding-left: 0;
    padding-right: 0;
}

.createNewText{
    color: #00649b;
}
.createNewText:hover{
    color: #00415e;
}

div.k-content.k-active{
    padding: 0;
}
path{
    fill: #00415E;
}

.font-italic{ 
    font-style: italic !important; 
}

.tooltip-inner{
    background-color: #00415E !important;
}

.k-grid-container{
    cursor: pointer;
}
